<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            optionList: options$ | async,
            values: values.$ | async,
            optionClass: multiple || forceCheckbox ? 'bazis-checkbox' : 'bazis-radio',
            disabled: disabled.$ | async
        } as data"
    >
        <div
            *ngIf="ngControl.control; let control"
            [class]="'bazis-control ' + cssClassSizeControl"
            [ngClass]="{
                'bazis-control--invalid': ngControl.control.invalid,
                'bazis-control--disabled': data.disabled
            }"
        >
            <bazis-label
                [isMajor]="hasTitleMajor"
                [isEmptyLabel]="isEmptyLabel"
                [title]="title || t(titleKey, titleParams)"
                [required]="data.required"
            >
                <bazis-interrogation
                    *ngIf="tooltipKey"
                    [slot]="tooltipSettings?.targetSide"
                    iconSize="xs"
                    [tooltipText]="t(tooltipKey, tooltipParams)"
                    [tooltipOffset]="[-8, 4]"
                    [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                    [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                    [icon]="icons.interrogation"
                    [type]="interrogationType"
                ></bazis-interrogation>
            </bazis-label>

            <div [ngClass]="[isOneline ? 'bh-flex bh-align-items-center' : '']">
                <div
                    *ngFor="let option of data.optionList; let i = index"
                    [ngClass]="[
                        data.optionClass,
                        direction === 'right' ? data.optionClass + '_right' : ''
                    ]"
                    [class.bh-inline-block]="isOneline"
                    [class.bazis-checkbox_mixed]="isSelectedPartial"
                >
                    <label (click)="toggleValue(option.id)">
                        <input
                            [type]="multiple || forceCheckbox ? 'checkbox' : 'radio'"
                            [disabled]="data.disabled"
                            [checked]="data.values.indexOf(option.id) > -1 || isSelectedPartial"
                            (change)="preventDefault($event)"
                            (click)="preventDefault($event)"
                        />
                        <span
                            [ngClass]="[
                                data.optionClass + '__label',
                                hasLabelMajor ? 'bh-font-medium' : '',
                                option.$snapshot.name || option.$snapshot.nameKey
                                    ? ''
                                    : 'bazis-label-hidden'
                            ]"
                        >
                            <bazis-item-with-icon-value
                                [item]="option"
                                iconSize="xs"
                            ></bazis-item-with-icon-value>
                            <span
                                color="danger"
                                *ngIf="option.$snapshot.required"
                            >
                                *
                            </span>
                        </span>
                    </label>
                </div>

                <!-- <ng-container *ngIf="tooltipKey">
                    <bazis-interrogation
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                        [tooltipPlacement]="tooltipSettings?.placement || 'bottom-start'"
                        [tooltipIsStatic]="false"
                [icon]="icons.interrogation"
            [type]="interrogationType"
                    ></bazis-interrogation>
                </ng-container> -->
            </div>
        </div>
    </ng-container>
</ng-container>
